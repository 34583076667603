<template>
  <div class="tabs" id="fixedBar" :class="{ fixedBar: isFixed }">
    <button
      v-for="(tab, index) in tabs"
      :key="'tab' + index"
      class="tab"
      :class="{ active: index === activeTab }"
      @click="clickTab(index)"
    >
      {{ tab }}
    </button>
  </div>
</template>

<script>
import bus from '@/assets/js/bus.js'

export default {
  name: 'Tabs',
  components: {},
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      },
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFixed: false,
      offsetTop: 0,
      marginTop: 0,
      scrollWithThrottle: null,
    }
  },
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      var startHeight = document.querySelector('#tab-container').offsetTop + 32

      if (scrollTop >= startHeight) {
        this.isFixed = true
        document.querySelector('#tab-container').style.paddingTop = '104px'
      } else {
        this.isFixed = false
        document.querySelector('#tab-container').style.paddingTop = ''
      }
    },
    throttle(func, timeout = 250) {
      let last
      let timer

      return function() {
        const context = this
        const args = arguments
        const now = +new Date()

        if (last && now < last + timeout) {
          clearTimeout(timer)
          timer = setTimeout(function() {
            last = now
            func.apply(context, args)
          }, timeout)
        } else {
          last = now
          func.apply(context, args)
        }
      }
    },
  },
  mounted() {
    this.scrollWithThrottle = this.throttle(this.handleScroll)
    window.addEventListener('scroll', this.scrollWithThrottle)
  },
  unmounted() {
    window.removeEventListener('scroll', this.scrollWithThrottle)
  },
  setup() {
    function clickTab(index) {
      bus.emit('clickTab', index)
    }
    return { clickTab }
  },
}
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  background-color: #faf8f2;
  justify-content: space-between;
  padding: 10px 0;
  transition: all 0.3s;
  position: relative;
  .tab {
    display: block;
    flex: none;
    flex-grow: 0;
    color: #545454;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.32px;
    background-color: transparent;
    box-shadow: none;
    max-width: none;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    &.active {
      color: #9d8147;
      position: relative;
      &::after {
        position: absolute;
        bottom: -10px;
        content: '';
        display: block;
        height: 3px;
        background: #ceb17d;
        border-radius: 8px;
        width: 38%;
        left: 31%;
      }
    }
  }
}

.fixedBar {
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  left: 0;
  background: #faf8f2;
  box-shadow: 0px 4px 16px rgba(56, 56, 56, 0.05);
}
::-webkit-scrollbar {
  display: none;
}
</style>
