<template>
  <div class="Member">
    <div v-html="hotel.clubProfile" class="intro"></div>
    <h3>加入會員</h3>
    <div v-html="hotel.joinClubInfo" class="usage"></div>
  </div>
</template>

<script>
export default {
  name: 'Member',
  components: {},
  props: {
    hotel: {
      type: Object,
      default() {
        return {
          clubProfile: '',
          joinClubInfo: '',
        }
      },
    },
  },
  data() {
    return {}
  },
  created() {},
}
</script>

<style scoped lang="scss">
.intro {
  padding-bottom: 24px;
}
.usage {
  padding-top: 16px;
}
h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.32px;
  color: #ceb17d;
}
</style>
