<template>
  <div class="Parking">
    <div v-if="info.address" class="top-section">
      <div class="address pt-1 pb-2">{{ info.address }}</div>
      <div>
        <button @click="openMap(info)" class="navigation">地圖導航</button>
      </div>
    </div>
    <h3>停車權益</h3>
    <div v-html="info.description" class="usage"></div>
    <h3 v-if="info.parkingList.length >= 1">鄰近停車場</h3>
    <div class="parking-lot">
      <div
        v-for="(park, index) in info.parkingList"
        :key="'park' + index"
        class="park"
      >
        <div class="location">
          <div class="name truncate">{{ park.title }}</div>
          <div class="address truncate">{{ park.address }}</div>
        </div>
        <button @click="openMap(park)" class="navigation">地圖導航</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Parking',
  components: {},
  props: {
    info: {
      type: Object,
      default() {
        return {
          address: '',
          description: '',
          parkingList: [],
        }
      },
    },
  },
  methods: {
    openMap(locate) {
      //latitude緯度,longitude經度
      window.openUrlExternal(
        `https://www.google.com/maps/dir/?api=1&destination=${locate.latitude},${locate.longitude}`
      )
    },
  },
}
</script>

<style scoped lang="scss">
.top-section {
  padding: 8px 4.8%;
  background-color: #fefdfb;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 24px;
  .address {
    letter-spacing: -0.32px;
    color: #020202;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
}
.navigation {
  color: #9d8147;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  margin: auto;
  margin-right: 0;
  background: #ffffff;
  padding: 0px 0px;
  border-radius: 100px;
  box-shadow: none;
  flex: 0 0 auto;
  &::before {
    content: '';
    display: inline-block;
    background-image: url('../../assets/img/icon/navigation.svg');
    width: 16px;
    height: 16px;
    margin-right: 8px;
    vertical-align: text-bottom;
  }
}
h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.32px;
  color: #ceb17d;
}
.usage {
  padding: 16px 0 24px;
}
.parking-lot {
  padding: 20px 0;
  .park {
    display: flex;
    justify-content: space-between;
    background: #fefdfb;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 8px 4.8%;
    & ~ .park {
      margin-top: 8px;
    }
    .location {
      width: 74%;
      flex: 0 0;
      .name {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.32px;
        color: #020202;
      }
      .address {
        padding-top: 4px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #545454;
      }
    }
  }
}
</style>
