<template>
  <div class="store" :class="{ 'scroll-lock': itemSwitchShow }">
    <Header :info="states.info" :actions="states.actions" />
    <div v-if="states.eventList.length" class="event-section">
      <DecoTitle title="主題精選" />
      <EventSwiper :eventList="states.eventList" chip="0" :tab="storeId" />
    </div>
    <div class="store-content" id="tab-container">
      <Tabs :tabs="states.tabs" :activeTab="activeTab" class="mb-6" />
      <transition name="fade" mode="out-in">
        <div v-if="activeTab == 0" class="room-list">
          <Chips
            :chips="states.roomChips"
            :activeChip="activeChip"
            emit="clickChip"
            class="mb-2"
          />
          <div class="bed-style pb-3">
            <img src="../assets/img/icon/bed.svg" />
            <div>床型：</div>
            <div v-for="(bed, idx) in filtedBedList" :key="idx">
              {{ bed.title }}
              <template v-if="idx + 1 < filtedBedList.length">,</template>
            </div>
          </div>
          <ShowCard
            v-for="(room, index) in filtedRoomList"
            :key="'room' + index"
            :info="room"
            @click="toRoom(room.originIndex)"
          />
        </div>
        <div v-else-if="activeTab == 1" class="dining-list">
          <ShowCard
            v-for="(dining, index) in states.diningList"
            :key="'dingin' + index"
            :info="dining"
            @click="toDining(dining.id)"
          />
        </div>
        <Parking v-else-if="activeTab == 2" :info="states.parkingInfo" />
        <Member v-else-if="activeTab == 3" :hotel="states.info" />
      </transition>
    </div>
    <transition name="fade">
      <BottomModal
        :open="openMenu"
        title="線上菜單"
        @close="
          () => {
            openMenu = false
          }
        "
      >
        <div v-if="selectedMenu.length" class="menu-list">
          <MenuItem
            v-for="(menu, index) in selectedMenu"
            :key="'menu' + index"
            :menu="menu"
          />
        </div>
      </BottomModal>
    </transition>
    <div
      v-if="states.info.calendarUrl"
      class="fixed fixedBottom w-full text-center calendar-button-block"
    >
      <div @click="toCalendarUrl" class="calendar">淡旺季行事曆</div>
    </div>
    <ItemSwitch
      class="item-switch"
      :class="{ 'item-switch-show': itemSwitchShow }"
      :show="itemSwitchShow"
      :title="itemSwitchTitle"
      :itemList="restaurantList"
      @handle-click="closeItemSwitch"
    />
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import DecoTitle from '@/components/decoTitle.vue'
import EventSwiper from '@/components/eventSwiper.vue'
import ShowCard from '@/components/showCard.vue'
import Tabs from '@/components/tabs.vue'
import Chips from '@/components/chips.vue'
import Parking from '@/components/store/parking.vue'
import Member from '@/components/store/member.vue'
import BottomModal from '@/components/bottomModal.vue'
import MenuItem from '@/components/menuItem.vue'
import ItemSwitch from '@/components/itemSwitch.vue'
import bus from '@/assets/js/bus.js'
import api from '@/api/api'
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
  name: 'Store',
  components: {
    Header,
    EventSwiper,
    DecoTitle,
    ShowCard,
    Tabs,
    Chips,
    Parking,
    Member,
    BottomModal,
    MenuItem,
    ItemSwitch,
  },
  data() {
    return {
      activeTab: 0,
      activeChip: '',
      selectedMenu: [],
      openMenu: false,
    }
  },
  computed: {
    filtedRoomList() {
      if (this.activeChip) {
        return this.states.roomList.filter(
          room => room.title == this.activeChip
        )
      } else {
        return this.states.roomList
      }
    },
    filtedBedList() {
      let bedList = []
      let bedChips = []
      this.filtedRoomList.forEach(room => {
        bedList = bedList.concat(room.bedType)
      })
      bedList = [...new Set(bedList)]
      bedList.forEach(code => {
        let chip = this.states.bedType.find(bed => bed.code == code)
        if (chip) bedChips.push({ title: chip.typeName })
      })
      return bedChips
    },
    storeId() {
      return this.$route.params.sid || '0'
    },
  },
  methods: {
    toCalendarUrl() {
      window.openUrlExternal(this.states.info.calendarUrl)
    },
    toRoom(index) {
      this.$router.push({
        name: 'Room',
        params: { sid: this.$route.params.sid, rid: index },
      })
    },
    toDining(id) {
      this.$router.push({
        name: 'Dining',
        params: { sid: this.$route.params.sid, did: id },
      })
    },
  },
  created() {
    const activeTab = localStorage.getItem('activeTab')
    if (activeTab) {
      this.activeTab = Number(activeTab)
      localStorage.removeItem('activeTab')
    }

    bus.on('clickTab', index => {
      this.activeTab = index
      if (!index) this.activeChip = ''
    })
    bus.on('clickChip', index => {
      this.activeChip = this.states.roomChips[index].title
    })
    bus.on('showMenu', menu => {
      this.openMenu = true
      this.selectedMenu = [...menu]
    })
  },
  mounted() {
    window.scrollTo({
      top: 150,
      behavior: 'smooth',
    })
  },
  beforeUnmount() {
    bus.all.clear()
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const states = reactive({
      info: computed(() =>
        store.state.hotelList.find(hotel => hotel.id == route.params.sid)
      ),
      actions: [],
      eventList: [],
      roomList: [],
      roomChips: [],
      bedType: [],
      diningList: [],
      tabs: ['房型介紹', '精選美饌', '交通資訊', '會館會員'],
    })
    if (!states.info.clubProfile && !states.info.joinClubInfo) {
      states.tabs.pop()
    }
    states.actions = [
      {
        actionName: '住宿線上預訂',
        action: () => {
          window.openUrlExternal(states.info.bookingUrl)
        },
      },
      {
        actionName: '美饌線上預訂',
        action: () => {
          const url = states.info.reservationUrl

          if (url.includes('inline')) {
            openItemSwitch()
          } else {
            window.openUrlExternal(url)
          }
        },
      },
    ]

    api
      .ActivityList({
        hotelId: route.params.sid,
        restaurantId: '',
        pageType: 4,
      })
      .then(result => {
        states.eventList = result.data
          .slice(0, 7)
          .filter(event => event.type != 3)
      })
      .catch(err => {
        console.error('取得活動失敗', err)
      })

    api
      .RoomList({
        hotelId: route.params.sid,
      })
      .then(result => {
        states.roomList = result.data.rooms
        states.roomList.forEach((room, index) => {
          room.originIndex = index
          room.image = room.image[0]
          if (
            states.roomChips.findIndex(chip => chip.title == room.title) == -1
          ) {
            states.roomChips.push({ title: room.title })
          }
        })
        states.bedType = result.data.bedType
      })
      .catch(err => {
        console.error('取得房間失敗', err)
      })
    api
      .RestaurantList({
        hotelId: route.params.sid,
      })
      .then(result => {
        states.diningList = result.data
        states.diningList.forEach(dining => {
          dining.type = 0
        })
        store.commit('m_setSelectedDiningList', states.diningList)
      })
      .catch(err => {
        console.error('取得餐廳失敗', err)
      })
    api
      .ParkingInfo({
        hotelId: route.params.sid,
      })
      .then(result => {
        states.parkingInfo = result.data
      })
      .catch(err => {
        console.error('取得停車失敗', err)
      })

    const itemSwitchShow = ref(false)
    const itemSwitchTitle = ref('')
    const restaurantList = ref([])
    const openItemSwitch = () => {
      itemSwitchShow.value = true
      itemSwitchTitle.value = states.info.title
      restaurantList.value = states.diningList.filter(item => {
        return item.reservationUrl ? item : null
      })
    }
    const closeItemSwitch = value => {
      itemSwitchShow.value = value
    }
    return {
      states,
      store,
      itemSwitchShow,
      itemSwitchTitle,
      restaurantList,
      openItemSwitch,
      closeItemSwitch,
    }
  },
}
</script>

<style scoped lang="scss">
.store {
  position: relative;
  &.scroll-lock {
    height: 100vw;
    overflow: hidden;
  }
}
.event-section {
  background: #fffdfc;
  box-shadow: 0px 4px 16px #ede9db;
}
.store-content {
  padding: 32px 5.33% 99px;
  min-height: 105vh;
  .title {
    padding-bottom: 17px;
  }
}
.fixedBottom {
  bottom: 10px;
}
.calendar-button-block {
  z-index: 2;
}
.calendar {
  cursor: pointer;
  display: inline-block;
  padding: 9px 16px;
  background: rgba(206, 177, 125, 0.92);
  backdrop-filter: blur(40px);
  border-radius: 100px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: -0.32px;
  line-height: 24px;
}

.bed-style {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
  div {
    font-size: 13px;
    line-height: 26px;
    color: #545454;
    margin-left: 8px;
  }
}
.item-switch {
  position: fixed;
  z-index: 20;
  bottom: -600px;
  transition: bottom 0.5s ease;

  &.item-switch-show {
    bottom: 0px;
  }
}
</style>
