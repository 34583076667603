<template>
  <div class="item-switch">
    <div class="content-block">
      <div class="title">
        <div class="image" @click="closeItemSwitch">
          <img :src="ImageCross" alt="" />
        </div>
        <div class="name">{{ title }}</div>
      </div>
      <div class="item-block">
        <div
          class="item"
          v-for="(item, idx) in innerList"
          :key="idx"
          @click="goTo(idx)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div v-show="show" class="overlay" @click.stop=""></div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import ImageCross from '../assets/img/icon/close_black.svg'

export default {
  name: 'ItemSwitch',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    itemList: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  emits: ['handle-click'],
  setup(props, { emit }) {
    const store = useStore()

    const innerList = computed(() => {
      return props.itemList
    })

    const goTo = idx => {
      const inleinTokenInfo = store.state.inleinTokenInfo
      const url = innerList.value[idx].reservationUrl
      const link = url.includes('inline')
        ? `${url}&pre_filled_form=${inleinTokenInfo}`
        : url
      window.openUrlExternal(link)
    }

    const closeItemSwitch = () => {
      emit('handle-click', false)
    }
    return {
      innerList,
      closeItemSwitch,
      goTo,
      ImageCross,
    }
  },
}
</script>

<style scoped lang="scss">
.item-switch {
  width: 100%;
}

.content-block {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  background: #fefdfb;
  width: 100%;
  max-height: 530px;
  padding: 24px 20px 58px 20px;
  position: relative;
  z-index: 18 !important;
  position: relative;
  bottom: 0;
  left: 0;

  .title {
    position: relative;
    display: flex;
    .image {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      left: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      font-weight: bold;
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      color: #222222;
    }
  }

  .item-block {
    margin-top: 24px;
    max-height: 235px;
    overflow: auto;
    .item {
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      background: #fffdfc;
      box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
    }
  }
}
.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
</style>
